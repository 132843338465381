import { getFeatureToggle } from '@/services/feature-toggle-service';
// This will retrieve the feature toggle "Showroom-Enhancements" which was created as a part of the DR initiative
// The name showroom-enhancements was chosen so it's generic and can be used for other features in the future.
export const isEnhancedShowroomEnabled = async (dealer) => {
    if (!dealer)
        return false;
    const featureName = 'Showroom_Enhancements';
    const featureEnabled = await getFeatureToggle(dealer, featureName)
        .then(r => r.isEnabled)
        .catch(() => {
        return false;
    });
    return featureEnabled;
};
