export const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});
export const mileageFormatter = {
    format: (mileage) => {
        const _mileage = parseInt(mileage);
        return Number.isNaN(_mileage) || _mileage < 0 ? '--' : mileage.toLocaleString();
    }
};
export const vdpURLFormatter = (shortName, vehicleData, searchParams) => {
    const vin = vehicleData.vin || 'vin';
    const year = vehicleData.year || 'year';
    let make = vehicleData.make || 'make';
    let model = vehicleData.model || 'model';
    make = make.replaceAll('/', '').replaceAll(' ', '');
    model = model.replaceAll('/', '').replaceAll(' ', '');
    return `/${shortName}/vehicle-details/${year}/${make}/${model}/${vin}${searchParams ? `?` + searchParams.toString() : ''}`;
};
