export var DeeplinkCustomerType;
(function (DeeplinkCustomerType) {
    DeeplinkCustomerType["PQ"] = "PQ";
    DeeplinkCustomerType["VISITOR"] = "VISITOR";
    DeeplinkCustomerType["EIDPNPQ"] = "EIDPNPQ";
    DeeplinkCustomerType["UNKNOWN"] = "UNKNOWN";
})(DeeplinkCustomerType || (DeeplinkCustomerType = {}));
export var LeadType;
(function (LeadType) {
    LeadType["C1"] = "C1";
    LeadType["CRM"] = "CRM";
})(LeadType || (LeadType = {}));
