import { v4 } from 'uuid';
import * as StorageUtilities from './storage-utilities';
export const getClientCorrelationId = () => {
    const currentCorrelationId = StorageUtilities.getSessionStorageValue('Client-Correlation-Id');
    if (currentCorrelationId) {
        return currentCorrelationId;
    }
    const clientCorrelationId = v4();
    StorageUtilities.setSessionStorageValue('Client-Correlation-Id', clientCorrelationId);
    return clientCorrelationId;
};
