import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TagKey } from '@cof/tag-catalog-white-label-ui';
import './ConfirmationPage.scss';
import { useEffect, useState } from 'react';
import checkCircle from '@/assets/icons/CheckCircle.svg';
import Error from '@/components/Error/Error';
import LandingPageHeader from '@/components/landing-page-header/landing-page-header';
import { publishTag } from '@/services/tagging-service';
export default ({ metadata }) => {
    useEffect(() => {
        document.title = 'Confirmation';
        publishTag(TagKey.PAGE_VIEW_22);
    }, []);
    const LandingPageContent = metadata?.deeplink?.LandingPageContentText?.split('\n');
    const [error, setSMSError] = useState(false);
    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isMobile = /windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPhone/i.test(userAgent);
        if (metadata?.dealerNumber && metadata?.deeplink?.SMSMessageContentText) {
            if (isMobile) {
                const message = encodeURIComponent(metadata?.deeplink?.SMSMessageContentText);
                const link = Object.assign(document.createElement('a'), {
                    href: `sms:+${metadata?.dealerNumber}?&body=${message}`
                });
                document.body.appendChild(link);
                window.requestAnimationFrame(function () {
                    const event = new MouseEvent('click');
                    link.dispatchEvent(event);
                    document.body.removeChild(link);
                });
            }
            else {
                setSMSError(true);
            }
        }
    }, []);
    return (_jsxs(_Fragment, { children: [metadata.dealerName && _jsx(LandingPageHeader, { dealerName: metadata.dealerName }), _jsx("div", { className: "ldp-confirmation-container", children: error ? (_jsx(Error, { variant: "sms" })) : (_jsxs("div", { className: "ldp-confirmation-content", children: [_jsx("img", { src: checkCircle, alt: "" }), LandingPageContent && (_jsxs(_Fragment, { children: [_jsx("h1", { className: "fs-heading-text--xlarge", "data-testid": "confirmation-header", children: _jsx(_Fragment, { children: LandingPageContent[0] }) }), _jsx("p", { className: "fs-body-text--normal", children: LandingPageContent[1] })] }))] })) })] }));
};
