/* Available icons for body styles */
export const BODY_STYLES = {
    Sedan: 'Sedan',
    Convertible: 'Convertible',
    Coupe: 'Coupe',
    Crossover: 'Crossover',
    Hatchback: 'Hatchback',
    Van: 'Van',
    Minivan: 'Minivan',
    SUV: 'SUV',
    Sportutilityvehicle: 'SUV',
    Truck: 'Truck',
    Pickup: 'Truck',
    Wagon: 'Wagon',
    Stationwagon: 'Wagon'
};
/* Available colors for interior & exterior, following Consumer-UI/auto-navigator's lib/vehicle-details-page */
export const COLORS = ['black', 'gray', 'silver', 'white', 'red', 'yellow', 'tan', 'gold', 'orange',
    'bronze', 'beige', 'brown', 'green', 'turquoise', 'blue', 'purple', 'violet']; //prettier-ignore
export const prequalSourceId = 'S-DA-1234567890A-DAD-0066';
export const vehicleShareSourceId = 'S-DA-1234567890A-DAD-0067';
export const VEHICLE_CONDITIONS = {
    New: 'new',
    Used: 'used'
};
export const LEAD_ATTRIBUTION_LEAD_PROVIDER = 'QRCode';
export const overageFee = {
    acura: {
        overage: '$0.20',
        disposition: '$350'
    },
    'alfa romeo': {
        overage: '$0.25',
        disposition: '$395'
    },
    'aston martin': {
        overage: '',
        disposition: ''
    },
    bentley: {
        overage: '$0.35',
        disposition: '$495'
    },
    bmw: {
        overage: '$0.25',
        disposition: '$350'
    },
    buick: {
        overage: '$0.25',
        disposition: '$350'
    },
    cadillac: {
        overage: '$0.25',
        disposition: '$350'
    },
    chevrolet: {
        overage: '$0.25',
        disposition: '$350'
    },
    chrysler: {
        overage: '$0.25',
        disposition: '$395'
    },
    dodge: {
        overage: '$0.25',
        disposition: '$395'
    },
    fiat: {
        overage: '$0.25',
        disposition: '$395'
    },
    ford: {
        overage: '$0.20',
        disposition: '$395'
    },
    genesis: {
        overage: '$0.25',
        disposition: '$400'
    },
    gmc: {
        overage: '$0.25',
        disposition: '$395'
    },
    honda: {
        overage: '$0.15',
        disposition: '$350'
    },
    hyundai: {
        overage: '$0.20',
        disposition: '$400'
    },
    infiniti: {
        overage: '$0.25',
        disposition: '$395'
    },
    jaguar: {
        overage: '$0.30',
        disposition: '$495'
    },
    jeep: {
        overage: '$0.25',
        disposition: '$395'
    },
    kia: {
        overage: '$0.20',
        disposition: '$400'
    },
    'land rover': {
        overage: '$0.30',
        disposition: '$495'
    },
    lexus: {
        overage: '$0.25',
        disposition: '$350'
    },
    lincoln: {
        overage: '$0.25',
        disposition: '$395'
    },
    mini: {
        overage: '$0.25',
        disposition: '$350'
    },
    maserati: {
        overage: '$0.30',
        disposition: '$395'
    },
    mazda: {
        overage: '$0.15',
        disposition: '$350'
    },
    'mercedes-benz': {
        overage: '$0.25',
        disposition: '$595'
    },
    mitsubishi: {
        overage: '$0.20',
        disposition: '$350'
    },
    nissan: {
        overage: '$0.15',
        disposition: '$395'
    },
    porsche: {
        overage: '$0.30',
        disposition: '$595'
    },
    ram: {
        overage: '$0.25',
        disposition: '$395'
    },
    'rolls royce': {
        overage: '',
        disposition: ''
    },
    scion: {
        overage: '$0.15',
        disposition: '$350'
    },
    smart: {
        overage: '$0.25',
        disposition: '$150'
    },
    subaru: {
        overage: '$0.15',
        disposition: '$300'
    },
    tesla: {
        overage: '',
        disposition: '$395'
    },
    toyota: {
        overage: '$0.15',
        disposition: '$350'
    },
    volkswagen: {
        overage: '$0.20',
        disposition: '$395'
    },
    volvo: {
        overage: '$0.25',
        disposition: '$350'
    }
};
export const defaultTheme = {
    main: '#0276B1',
    light: '#F0FAFF'
};
