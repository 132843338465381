/* eslint-disable compat/compat */
export class MFEVitals extends HTMLElement {
    constructor() {
        super(...arguments);
        this._name = '';
        this._ver = '';
    }
    static get observedAttributes() {
        return ['mfe-name', 'mfe-version'];
    }
    connectedCallback() {
        const startTime = performance.now();
        const mutationObserver = new MutationObserver(() => {
            const mfe = this.querySelector(this._name);
            if (mfe == null)
                return;
            window.customElements?.whenDefined(this._name).then(() => {
                if (mfe.shadowRoot?.innerHTML === undefined)
                    return;
                publish(this._name, this._ver, 'TTR', Math.round(performance.now() - startTime));
                requestIdleCallback(() => publish(this._name, this._ver, 'TTI', Math.round(performance.now() - startTime)), { timeout: 1500 } // Timeout for 1500 milliseconds
                );
                mutationObserver.disconnect();
            });
        });
        mutationObserver.observe(this, { childList: true, subtree: true });
    }
    attributeChangedCallback(attr, _, val) {
        if (attr === 'mfe-name')
            this._name = val;
        else if (attr === 'mfe-version')
            this._ver = val;
    }
}
export const publish = (name, ver, vital, time) => console.log(`[${name}] ${vital}: ${time}ms`); //TODO: Remove console log
// window.newrelic?.addPageAction('mfe_vital', { component_id: name, version: ver, [vital]: time }); //TODO: Publish to NR
