/* eslint-disable prettier/prettier */
export const applyThemeToFSComponent = (elementId, mainColor) => {
    if (mainColor) {
        document.getElementById(elementId)
            ?.style.setProperty('--fs-color-interactive-primary-default', mainColor);
        document.getElementById(elementId)
            ?.style.setProperty('--fs-color-focus-primary-default', mainColor);
        document.getElementById(elementId)
            ?.style.setProperty('--fs-color-interactive-primary-hover', mainColor);
        document.getElementById(elementId)
            ?.style.setProperty('--fs-color-interactive-primary-focus', mainColor);
        document.getElementById(elementId)
            ?.style.setProperty('--fs-color-interactive-primary-active', mainColor);
    }
};
