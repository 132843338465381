export default class StratumTaggingQueue {
    constructor() {
        this.taskQueue = [];
        this.isBusy = false;
    }
    enqueue(job) {
        new Promise((resolve, reject) => {
            this.taskQueue.push({ job, resolve, reject });
        });
    }
    nextJob() {
        if (this.isBusy)
            return;
        const next = this.taskQueue.shift();
        if (next) {
            this.isBusy = true;
            next
                .job()
                .then((value) => {
                next.resolve(value);
                this.isBusy = false;
                this.nextJob();
            })
                .catch((error) => {
                next.reject(error);
                this.isBusy = false;
                this.nextJob();
            });
        }
    }
}
