import packageInfo from '@/../package.json';
/* New Relic: Initialize Data */
export const initNewRelicData = () => {
    setNewRelicAttribute('LandingPageVersion', packageInfo.version);
    logNewRelicAction("landing-page-loaded" /* ACTION.LOADED */);
};
/* New Relic: Set Attribute */
const setNewRelicAttribute = (name, value) => (window.newrelic ?? window.NREUM)?.setCustomAttribute(name, value);
/* New Relic: Log Action */
export const logNewRelicAction = (action, attributes = {}) => (window.newrelic ?? window.NREUM)?.addPageAction(`landing-page:${action}`, attributes);
