import { registerDealerComments } from '@cof/an-shared-lib-dealer-comments';
import { FsAccordion, FsAccordionItem } from '@cof/fs-accordion-wc';
import { FsButton } from '@cof/fs-button-wc';
import { FsCircularLoader } from '@cof/fs-circular-loader-wc';
import { FsContainer } from '@cof/fs-container-wc';
import { FsDivider } from '@cof/fs-divider-wc';
import { FsIcon } from '@cof/fs-icon-wc';
import { fs16ArrowRight, fs16CautionFilled, fs16ChevronLeft, fs16ChevronRight, fs16Information, fs16Lock, fs24ArrowRight, fs24BodyStyleConvertibleFilled, fs24BodyStyleCoupeFilled, fs24BodyStyleCrossoverFilled, fs24BodyStyleHatchbackFilled, fs24BodyStyleMinivanFilled, fs24BodyStyleSedanFilled, fs24BodyStyleSuvFilled, fs24Lightbulb, fs24ThumbsUp, fs24BodyStyleTruckFilled, fs24BodyStyleVanFilled, fs24BodyStyleWagonFilled, fs24ChevronDown, fs24ChevronLeft, fs24ChevronRight, fs24ChevronUp, fs24Close, fs24CloseFilled, fs24CheckmarkCircle, fs24CheckmarkCircleFilled, fs24Dealership, fs16LinkExternal, fs24Offer, fs24PhoneCircleFilled, fs16UserFilled, fs24Phone, fs24MailEnvelope, fs24Chat, fs24MapPin, fs24Calendar, fs24Menu, fs16ChevronDown } from '@cof/fs-icons';
import { FsLabelBadge } from '@cof/fs-label-badge-wc';
import { FsModalDialog, FsModalDialogFooter } from '@cof/fs-modal-dialog-wc';
import { PhoneNumberField } from '@cof/fs-phone-number-field-wc';
import { FsSkeletonLoader } from '@cof/fs-skeleton-loader-wc';
import { FsTextField } from '@cof/fs-text-field-wc';
import { FsTooltip } from '@cof/fs-tooltip-wc';
import { MFEVitals } from '@/utils/mfe-vitals';
import '@cof/cb-expandable-disclosures'; // Bug in component: registers custom element despite named import
customElements.define('fs-accordion', class extends FsAccordion {
});
customElements.define('fs-accordion-item', class extends FsAccordionItem {
});
customElements.define('cb-ui-circular-loader', class extends FsCircularLoader {
});
customElements.define('fs-modal-dialog', class extends FsModalDialog {
});
customElements.define('fs-modal-dialog-footer', class extends FsModalDialogFooter {
});
customElements.define('fs-text-field', class extends FsTextField {
});
customElements.define('fs-phone-number-field', class extends PhoneNumberField {
});
customElements.define('fs-button', class extends FsButton {
});
customElements.define('fs-container', class extends FsContainer {
});
customElements.define('fs-icon', class extends FsIcon {
});
customElements.define('fs-label-badge', class extends FsLabelBadge {
});
customElements.define('fs-skeleton', class extends FsSkeletonLoader {
});
customElements.define('fs-tooltip', class extends FsTooltip {
});
customElements.define('c1-mfe-vitals', class extends MFEVitals {
});
customElements.define('fs-divider', class extends FsDivider {
});
registerDealerComments();
FsIcon.add([
    fs16ArrowRight,
    fs16CautionFilled,
    fs16ChevronLeft,
    fs16ChevronRight,
    fs16Information,
    fs16LinkExternal,
    fs16Lock,
    fs24ArrowRight,
    fs24BodyStyleConvertibleFilled,
    fs24BodyStyleCoupeFilled,
    fs24BodyStyleCrossoverFilled,
    fs24BodyStyleHatchbackFilled,
    fs24BodyStyleMinivanFilled,
    fs24BodyStyleSedanFilled,
    fs24BodyStyleSuvFilled,
    fs24Lightbulb,
    fs24ThumbsUp,
    fs24BodyStyleTruckFilled,
    fs24BodyStyleVanFilled,
    fs24BodyStyleWagonFilled,
    fs24ChevronDown,
    fs24ChevronLeft,
    fs24ChevronRight,
    fs24ChevronUp,
    fs24Close,
    fs24CloseFilled,
    fs24CheckmarkCircle,
    fs24CheckmarkCircleFilled,
    fs24Dealership,
    fs24Offer,
    fs24PhoneCircleFilled,
    fs16UserFilled,
    fs24Phone,
    fs24MailEnvelope,
    fs24Chat,
    fs24MapPin,
    fs24Calendar,
    fs24Menu,
    fs16ChevronDown
]);
