import * as fetchIntercept from 'fetch-intercept';
export const fetchInterceptor = () => fetchIntercept.register({
    request(url, config) {
        // Don't modify path if it is a relative path
        if (url.startsWith('http')) {
            return [url, config];
        }
        const modifiedPath = url.replace('/protected/', `${window.ENV_DATA.prefix}/protected/`);
        const modifiedUrl = `${window.location.origin}${modifiedPath}`;
        return [modifiedUrl, config];
    },
    requestError(error) {
        return Promise.reject(error);
    },
    response(response) {
        return response;
    },
    responseError(error) {
        return Promise.reject(error);
    }
});
