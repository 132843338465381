export const DEALER_GROUPS = {
    49802: ['27028', '27464', '28974', '33208', '43166', '43705', '37983', '45282', '50186']
};
export const DEALER_GROUPS_ADDITIONAL_DATA = {
    49802: {
        27028: {
            qa: {
                leadsNavClientToken: '412543a7-4fb3-4162-836b-8999ba58d315',
                dealerDisplayName: 'Easterns Automotive Group Laurel'
            },
            prod: {
                leadsNavClientToken: 'cde7ead3-7702-4482-81a7-3eb05401767c',
                dealerDisplayName: 'Easterns Automotive Group Laurel'
            }
        },
        27464: {
            qa: {
                leadsNavClientToken: '608068be-4197-4434-85c7-34199d0e3cc3',
                dealerDisplayName: 'Easterns Automotive Group Temple Hills'
            },
            prod: {
                leadsNavClientToken: '848ad3d9-cbdd-4b1d-be23-e175ff3ea15d',
                dealerDisplayName: 'Easterns Automotive Group Temple Hills'
            }
        },
        28974: {
            qa: {
                leadsNavClientToken: 'fec25aed-b6e7-4b41-8f8d-c9c00c80bc34',
                dealerDisplayName: 'Easterns Automotive Group Baltimore'
            },
            prod: {
                leadsNavClientToken: '8e315d6d-52c9-4393-a73a-5e510f2a1bb2',
                dealerDisplayName: 'Easterns Automotive Group Baltimore'
            }
        },
        33208: {
            qa: {
                leadsNavClientToken: 'c8fa66ad-b6c3-4bdd-9dad-ac036eb1d04f',
                dealerDisplayName: 'Easterns Automotive Group Hyattsville'
            },
            prod: {
                leadsNavClientToken: '7f1d6fc5-b47c-4417-9522-3c1b82e16209',
                dealerDisplayName: 'Easterns Automotive Group Hyattsville'
            }
        },
        43166: {
            qa: {
                leadsNavClientToken: 'd4704eaa-89de-471c-bd9b-84238954d841',
                dealerDisplayName: 'Easterns Automotive Group Alexandria'
            },
            prod: {
                leadsNavClientToken: '3e5d2018-ce6e-4d9a-8100-0a82bf96ca4d',
                dealerDisplayName: 'Easterns Automotive Group Alexandria'
            }
        },
        43705: {
            qa: {
                leadsNavClientToken: '8dc3962e-43a3-4c61-852a-e77b411c032b',
                dealerDisplayName: 'Easterns Automotive Group Sterling'
            },
            prod: {
                leadsNavClientToken: '7fcdb477-41d1-4321-9186-d03510b9bf84',
                dealerDisplayName: 'Easterns Automotive Group Sterling'
            }
        },
        37983: {
            qa: {
                leadsNavClientToken: '1cb70d23-88f3-40a5-a603-e89f803b23db',
                dealerDisplayName: 'Easterns Automotive Group Glen Burnie'
            },
            prod: {
                leadsNavClientToken: 'a4a6155b-f384-4ec2-8186-52bcbc222499',
                dealerDisplayName: 'Easterns Automotive Group Glen Burnie'
            }
        },
        45282: {
            qa: {
                leadsNavClientToken: 'f680236b-9d08-445b-94c6-d4a6b92fc467',
                dealerDisplayName: 'Easterns Automotive Group Frederick'
            },
            prod: {
                leadsNavClientToken: 'c26a4929-7d62-4103-9750-92bcba9b0f49',
                dealerDisplayName: 'Easterns Automotive Group Frederick'
            }
        },
        50186: {
            qa: {
                leadsNavClientToken: '0acc9af0-8d2d-4016-a175-4c6487e3e68c',
                dealerDisplayName: 'Easterns Automotive Group West Baltimore'
            },
            prod: {
                leadsNavClientToken: '195c1778-a974-4ec6-a7ae-de3c87a6adc6',
                dealerDisplayName: 'Easterns Automotive Group West Baltimore'
            }
        }
    }
};
export const findChildDealerInfo = (parentDealerId, childDealerId, keyToFind) => {
    const parentDealerData = DEALER_GROUPS_ADDITIONAL_DATA[parentDealerId];
    const env = window.ENV_DATA && window.ENV_DATA.isProd ? 'prod' : 'qa';
    if (!parentDealerData) {
        return undefined;
    }
    const childDealerData = parentDealerData[childDealerId];
    if (!childDealerData) {
        return undefined;
    }
    return DEALER_GROUPS_ADDITIONAL_DATA[parentDealerId][childDealerId][env][keyToFind];
};
