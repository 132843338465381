import { getClientCorrelationId } from '@/utils/correlation-id';
const baseHeaders = {
    Accept: 'application/json;v=1',
    'Client-Correlation-Id': getClientCorrelationId(),
    'Content-Type': 'application/json;v=1'
};
export const appendErrors = (response) => {
    //TODO: How are ids being handled across micro-services.
    const { id } = response;
    return {
        ...response,
        ...(id === '100001' && { isEmailError: true }),
        ...(id === '100002' && { isZipCodeError: true })
    };
};
export const getBaseHeaders = () => {
    return baseHeaders;
};
export const constructUrl = (...urlComponents) => {
    const validUrlComponents = urlComponents.filter(c => typeof c === 'string' && !!c);
    return [...validUrlComponents].join('/').replace(/\/{2,}/g, '/');
};
export const handleResponse = async (fetchResponse, defaultResponse) => {
    let response = defaultResponse;
    try {
        if (fetchResponse.ok || fetchResponse.status !== 204) {
            const serializedResponse = await fetchResponse.json();
            response = appendErrors(serializedResponse);
        }
        // eslint-disable-next-line no-empty
    }
    catch (e) { }
    return [fetchResponse?.status, response];
};
export const generateUUID = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};
