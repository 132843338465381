import { constructUrl, getBaseHeaders } from '@/services/service-config';
const FEATURE_TOGGLE_URL = '/protected/26699/auto/{applicationId}/dealers/{dealerId}/features/{featureName}';
export const getFeatureToggle = ({ dealerIds, webApplicationId }, featureName) => {
    const url = constructUrl(FEATURE_TOGGLE_URL.replace('{dealerId}', dealerIds)
        .replace('{featureName}', featureName)
        .replace('{applicationId}', webApplicationId));
    const options = {
        headers: { ...getBaseHeaders() }
    };
    const onSuccess = (r) => {
        if (!r.ok)
            throw Error('Feature Toggle request failed.');
        return r.json();
    };
    return fetch(url, options).then(onSuccess);
};
